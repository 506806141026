<template>
  <div class="d-flex flex-wrap">
    <div v-for="graph in selectedGraphs" :key="graph.name">
      <v-chip
        data-cy="graph-toggler"
        :color="getColor(graph)"
        :style="{ opacity: graph.isToggled ? '1' : '.5' }"
        closable
        small
        class="text--white ma-1"
        :disabled="!graph.isToggled && !canSelect(graph).allow"
        @click:close="deSelectGraph(graph)"
        @click="toggleGraph(graph)"
      >
        <span class="pr-2">{{ graph.name }}</span>
      </v-chip>
      <v-tooltip v-if="!graph.isToggled && !canSelect(graph).allow" activator="parent" location="bottom">
        {{ canSelect(graph).reason }}
      </v-tooltip>
    </div>
  </div>
</template>

<script lang="ts">
  import { Component, Vue, toNative } from 'vue-facing-decorator'

  import { TroubleshooterStore } from '#stores'

  import { Graph, SelectionGrant } from '#types/troubleshooter'

  @Component({})
  class GraphToggler extends Vue {
    private troubleShooterStore = new TroubleshooterStore()

    public get selectedGraphs() {
      return this.troubleShooterStore.selectedGraphs
    }

    public getColor(graph: Graph) {
      return graph.allowed ? graph.color : 'grey'
    }

    public deSelectGraph(graph: Graph) {
      this.troubleShooterStore.deSelectGraphs([graph])
    }

    public canSelect(graph: Graph): SelectionGrant {
      return this.troubleShooterStore.canSelect([{ ...graph, isToggled: true }])
    }

    public toggleGraph(graph: Graph) {
      this.troubleShooterStore.toggleGraphs([graph], !graph.isToggled)
    }
  }
  export default toNative(GraphToggler)
</script>

<style scoped lang="scss"></style>
